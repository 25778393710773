import React from 'react'
import Layout from '../components/layout'
import blogListLayout from '../components/blogList.module.scss'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Head from '../components/head'

const BlogPage = () => {
    const blogMetaData = useStaticQuery(graphql `
    query{
      allContentfulBlogPost(
        sort: {
          fields: publishedDate,
          order: DESC
        }
      ){
        edges{
          node{
            contentful_id
            title
            slug
            publishedDate(formatString: "MMMM DD YYYY")
          
          }
        }
      }
    }
      `
    )

    const posts = blogMetaData.allContentfulBlogPost.edges;

    return(
        <Layout>
          <div className="mainContentWidth">
          <Head title="Blog" />
          <h2> Writings</h2>
            <p>
              An on-going collection of personal writing and thoughts in the loose form of essays, articles, notes, prose and poems. These written works are an attempt to organise thoughts that span over time and numerous notebooks. Most are not finished pieces, rather they are fragments of fragile thoughts that may reveal something to me as to others as well as help document the N-1 project.
            </p>
            <div className={blogListLayout.space}>
            {
                posts.map(( {node} ) => {
                    const title = node.title;
                    const date = node.publishedDate;

                    const blogID = node.contentful_id;
               
                    return(
                      <Link className={blogListLayout.blogItems} to= { `/blog/${ node.slug }` } key={blogID}>
                          <div className={blogListLayout.blogPost}>                          
                            <li className={blogListLayout.blogTitle}>{title}</li>
                            <li></li>
                            <li className={blogListLayout.blogDate}>{date}</li>
                        </div>
                      </Link>
                    )

                })
            }
            </div> 
            </div>  
        </Layout>
    )
}

export default BlogPage